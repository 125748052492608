import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import styles from './Copilot.module.scss';
import { Button, Col, Container, Row } from 'react-dsgov';

const Copilot: React.FC = () => {
    const auth = useAuth();

    useEffect(() => {
        window.addEventListener('message', function(event) {
            if (event.data && event.data.height) {
                const iframe = document.getElementById('iframe-copilot');
                if (iframe) {
                    iframe.style.height = event.data.height + 'px';
                }
            }
        });
    }, []);
    
    return (
        <Container>
            {auth.isAuthenticated && <div className="iframe-container">
                <iframe
                    id="iframe-copilot"
                    className={styles.copilot}
                    src={`https://sisweb.tesouro.gov.br/apex/f?p=60122:2&token_azure=${auth.user?.access_token}`}
                    title="Copilot"
                ></iframe>
            </div>}
            {!auth.isAuthenticated && <Row mt="5">
                <Col mt="5">
                    <div><b>Atenção:</b> É necessário se autenticar para user o Copilot do Portal.</div>
                    <Button mt="2" primary onClick={() => auth.signinRedirect({redirect_uri: window.location.href})}><i className="fas fa-user" aria-hidden="true"></i> Clique aqui para entrar</Button>
                </Col>
            </Row>   
            }
        </Container>
        
    );
}

export default Copilot;